import VueRouter, { RawLocation } from "vue-router";

export function rootRoute(): RawLocation {
  return { name: "portfolio" };
}

export function fullUrl(router: VueRouter, route: RawLocation): string {
  const path = router.resolve(route).href;
  return new URL(path, window.location.origin).href;
}
