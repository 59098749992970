import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { pick } from "lodash";
import { GqlAsset } from "@/types";
import { deviceLock } from "@/gql/fragments/deviceLock";

export default function (asset: Partial<GqlAsset>): Promise<GqlAsset> {
  const fields = pick(asset, [
    "floorUuid",
    "name",
    "assetCategoryUuid",
    "assetTypeUuid",
    "manufacturerUuid",
    "assetModelUuid",
    "serialNumber",
    "installationDate",
    "floorX",
    "floorY"
  ]);

  return apolloClient
    .mutate({
      mutation: gql`
        ${deviceLock}
        mutation ($assetUuid: ID!, $parameters: UpdateAssetParams!) {
          updateAsset(assetUuid: $assetUuid, parameters: $parameters) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            installationDate
            floor {
              position
              name
            }
            floorUuid
            floorX
            floorY
            building {
              buildingUuid
              name
              timeZone
            }
            ... on Device {
              properties
              settings
              miscFields
              thresholds {
                values
              }
              ...DeviceLockFields
            }
            childLinks {
              linkType
              childAsset {
                assetUuid
                knownAssetUuid
                name
              }
            }
          }
        }
      `,
      variables: {
        assetUuid: asset.assetUuid,
        parameters: fields
      }
    })
    .then(response => {
      return response.data.updateAsset;
    });
}
