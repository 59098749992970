import Vue, { ComputedRef, computed, reactive } from "vue";
import { assign, forOwn, filter, ListIterateeCustom, isNil, fromPairs } from "lodash";
import { DateTime } from "luxon";
import {
  GqlAsset,
  DecoratedAsset,
  AssetConfig,
  PropertyConfig,
  DecoratedProperty,
  ThresholdPair,
  Unit,
  GqlAssetDataChange,
  GqlProperty,
  ThresholdFields,
  FieldDescriptor,
  ParamProvider,
  FieldDescriptorObject,
  UnitOrDefault,
  ChildLink,
  ParentLink,
  PropertySelection,
  PropertySelectionByName
} from "@/types";
import baseAssetConfig from "@/config/base-asset";
import { convertUnitWithFn, createConversionFns, round } from "@/utils/number";
import { parseTimestamp } from "@/utils/date";
import { convertPairsToThresholds } from "@/utils/models";
import {
  getIndexedField,
  setIndexedField,
  toDescriptorObject,
  descriptorMatchesDimensions
} from "@/utils/indexed-field";
import { getAssetConfig } from "./known-asset";

interface GetPropertyOptions {
  buildIfMissing?: boolean;
  unit?: UnitOrDefault;
}

export const DEFAULT_UNIT = "default_unit";

export function destinationUnit(propertyConfig: PropertyConfig): Unit | undefined {
  return propertyConfig.unitSelectorFn?.() ?? propertyConfig.unit;
}

export function getPropertyConfig(
  assetConfig: AssetConfig,
  descriptor: FieldDescriptor,
  unit: UnitOrDefault | null = null
): PropertyConfig {
  const { name } = toDescriptorObject(descriptor);
  let config: PropertyConfig | undefined = assetConfig.properties?.[name];
  if (!config) throw Error(`Property config not found for: ${name}`);

  const srcUnit = config.unit;
  const destUnit = unit === DEFAULT_UNIT ? config.unitSelectorFn?.() : unit;

  if (srcUnit && destUnit && destUnit !== srcUnit) {
    const altConfig = config.altUnits?.[destUnit] ?? {};

    config = {
      ...config,
      ...altConfig,
      unit: destUnit,
      ...createConversionFns(srcUnit, destUnit)
    };
  }

  return config;
}

export const formConfig = baseAssetConfig;

function buildThresholdFields(propertyConfig: PropertyConfig, thresholdArray: ThresholdPair[]): ThresholdFields {
  const convert = propertyConfig.convertValueFn;
  if (convert) {
    thresholdArray = thresholdArray.map(threshold => {
      return { ...threshold, compareValue: round(convert(threshold.compareValue)) ?? 0 };
    });
  }

  return {
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray)
  };
}

export function buildProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null
): DecoratedProperty {
  const propertyConfig = getPropertyConfig(asset.config, descriptor.name);
  const thresholdArray = asset.thresholds[descriptor.name] ?? [];

  return {
    ...descriptor,
    value: attributes?.value ?? null,
    timestamp: attributes?.stamp ?? null,
    update_stamp: attributes?.update_stamp ?? null,
    state: attributes?.state ?? null,
    pending: attributes?.pending ?? false,
    state_info: attributes?.state_info ?? {},
    ...buildThresholdFields(propertyConfig, thresholdArray),
    config: propertyConfig
  };
}

function createProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null
): DecoratedProperty {
  const property = buildProperty(asset, descriptor, attributes);
  setIndexedField(asset.properties, descriptor, property);
  return property;
}

function createProperties(asset: DecoratedAsset, gqlProperties: Record<string, GqlProperty<any> | null>): void {
  forOwn(gqlProperties, (gqlProperty, key) => {
    const descriptor = parseKey(asset.config, key);
    if (!descriptor) return;

    createProperty(asset, descriptor, gqlProperty);
  });
}

export function convertProperty(
  asset: DecoratedAsset,
  property: DecoratedProperty,
  unit: UnitOrDefault | null = DEFAULT_UNIT
): DecoratedProperty {
  if (!unit || unit === property.config.unit) return property;

  const config = getPropertyConfig(asset.config, property.name, unit);
  const { convertValueFn } = config;
  const params = assetParamProvider(asset);
  let { value, thresholdArray } = property;

  if (convertValueFn) {
    value = convertUnitWithFn(value, convertValueFn, params);
    thresholdArray = thresholdArray.map(threshold => ({
      ...threshold,
      compareValue: round(convertUnitWithFn(threshold.compareValue, convertValueFn, params)) ?? 0
    }));
  }

  return {
    ...property,
    value,
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray),
    config
  };
}

export function assetParamProvider(asset: DecoratedAsset): ParamProvider {
  const cache: Record<string, ComputedRef<any>> = {};
  return key => {
    cache[key] ||= computed(() => getOptionalProperty(asset, key)?.value);
    return cache[key].value;
  };
}

export function getProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  options: GetPropertyOptions = {}
): DecoratedProperty {
  return getOptionalProperty(asset, descriptor, { ...options, buildIfMissing: true }) as DecoratedProperty;
}

export function getOptionalProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  { buildIfMissing = false, unit = undefined }: GetPropertyOptions = {}
): DecoratedProperty | undefined {
  const descriptorObj = toDescriptorObject(descriptor);
  const { dimensions, category } = getPropertyConfig(asset.config, descriptorObj.name);

  if (category === "asset") {
    return getBuiltinProperty(asset, descriptorObj.name);
  }

  if (!descriptorMatchesDimensions(descriptorObj, dimensions)) {
    const { name, params } = descriptorObj;
    throw Error(`Incorrect params for property ${name} (${dimensions} needed): [${params}]`);
  }

  let property = getIndexedField(asset.properties, descriptorObj);

  if (!property && buildIfMissing) {
    property = buildProperty(asset, descriptorObj, {});
  }

  if (property && unit) {
    property = convertProperty(asset, property, unit);
  }

  return property;
}

export function getBuiltinProperty(asset: DecoratedAsset, name: string): DecoratedProperty {
  const descriptorObj = toDescriptorObject(name);
  const value = (asset as Record<string, any>)[name];
  return buildProperty(asset, descriptorObj, { value });
}

export function decorateAsset(asset: GqlAsset): DecoratedAsset {
  const { knownAsset, config } = getAssetConfig(asset.knownAssetUuid);

  const convertedFields = {
    installationDate: asset.installationDate ? DateTime.fromISO(asset.installationDate) : null
  };

  const locked = !!asset.deviceLock?.expiration;

  const decoratedAsset: DecoratedAsset = {
    ...asset,
    ...convertedFields,
    properties: {},
    thresholds: asset.thresholds?.values ?? {},
    config,
    knownAsset,
    locked
  };

  createProperties(decoratedAsset, asset.properties ?? {});
  createProperties(decoratedAsset, asset.settings ?? {});
  createProperties(decoratedAsset, asset.miscFields ?? {});

  return reactive(decoratedAsset);
}

function updateDecoratedAssetProperty(asset: DecoratedAsset, change: GqlAssetDataChange): void {
  const descriptor = parseKey(asset.config, change.property);
  if (!descriptor) return;

  const attributes: Partial<GqlProperty> = {
    value: change.value,
    stamp: change.stamp,
    update_stamp: change.updateStamp,
    state: change.state,
    pending: change.pending,
    state_info: change.stateInfo
  };

  let updateApplied = false;
  const currentProperty = getOptionalProperty(asset, descriptor);

  if (currentProperty) {
    const newProperty = buildProperty(asset, descriptor, attributes);
    if (shouldUpdateProperty(newProperty, currentProperty)) {
      // Copy into existing property to preserve reactivity
      checkPropertyCompletion(asset, currentProperty, newProperty, change.property);
      assign(currentProperty, newProperty);
      updateApplied = true;
    }
  } else {
    createProperty(asset, descriptor, attributes);
    updateApplied = true;
  }

  if (updateApplied && !isNil(change.deviceLock)) {
    asset.locked = change.deviceLock;
  }
}

export function updateAssetProperties(assets: DecoratedAsset[], changes: GqlAssetDataChange[]): void {
  changes.forEach(change => {
    const asset = assets.find(a => a.assetUuid === change.assetUuid);
    if (asset) {
      updateDecoratedAssetProperty(asset, change);
    }
  });
}

function shouldUpdateProperty(newProperty: DecoratedProperty, currentProperty: DecoratedProperty): boolean {
  const newTimestamp = parseTimestamp(newProperty.timestamp);
  if (newTimestamp === null) return false;

  const currentTimestamp = parseTimestamp(currentProperty.timestamp);
  if (currentTimestamp === null) return true;

  return newTimestamp > currentTimestamp;
}

export function startAssetUpdate(asset: DecoratedAsset, identifier: string, properties: string[]): void {
  const propPairs: [string, boolean][] = properties.map(p => [p, false]);
  const propObject = fromPairs(propPairs);

  Vue.set(asset, "updateStatus", {
    identifier,
    properties: propObject
  });
}

export function clearAssetUpdate(asset: DecoratedAsset): void {
  Vue.delete(asset, "updateStatus");
}

export function checkPropertyCompletion(
  asset: DecoratedAsset,
  currentProperty: DecoratedProperty,
  newProperty: DecoratedProperty,
  propertyKey: string
): void {
  if (!asset.updateStatus) return;

  if (currentProperty.pending && !newProperty.pending) {
    markPropertyComplete(asset, propertyKey);
  }
}

export function markPropertyComplete(asset: DecoratedAsset, propertyKey: string): void {
  if (!asset.updateStatus) return;
  asset.updateStatus.properties[propertyKey] = true;
}

export function assetUpdateMatchesIdentifier(asset: DecoratedAsset, identifier: string | null): boolean {
  const { locked, updateStatus } = asset;
  if (!(locked && updateStatus)) return false;
  if (!identifier) return true;
  return identifier === updateStatus.identifier;
}

export function updateThresholds(asset: DecoratedAsset, propertyName: string, thresholdArray: ThresholdPair[]): void {
  const propertyObj = getProperty(asset, propertyName);
  const thresholdFields = buildThresholdFields(propertyObj.config, thresholdArray);
  assign(propertyObj, thresholdFields);
}

function parseKey(assetConfig: AssetConfig, key: string): FieldDescriptorObject | undefined {
  const keyParts = key.match(/^(.*?)(\[.*)?$/);
  if (!keyParts) return undefined;

  const namePart = keyParts[1];
  const paramsPart: string | undefined = keyParts[2];

  const name = assetConfig.pathMap[namePart];
  if (!name) return undefined;
  let params: number[];

  if (paramsPart) {
    const numbers = paramsPart.match(/\d+/g);
    params = numbers ? numbers.map(m => parseInt(m)) : [];
  } else {
    params = [];
  }

  const fieldConfig = assetConfig.properties[name];
  if (!fieldConfig || fieldConfig.dimensions !== params.length) {
    return undefined;
  }

  return {
    name,
    params
  };
}

export function propertyKey(property: DecoratedProperty): string {
  const { name, params, config } = property;
  const key = config.key ?? name;
  const indicesStr = params.map(p => `[${p}]`).join("");
  return `${key}${indicesStr}`;
}

export function getParentAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ParentLink, boolean> | undefined
): DecoratedAsset[] {
  const parentLinks = asset.parentLinks ?? [];
  const matchingLinks = filter(parentLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.parentAsset));
}

export function getChildAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ChildLink, boolean> | undefined
): DecoratedAsset[] {
  const childLinks = asset.childLinks ?? [];
  const matchingLinks = filter(childLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.childAsset));
}

export function isPropertySelectionByName(
  selection: PropertySelection | PropertySelectionByName
): selection is PropertySelectionByName {
  return typeof selection.property === "string";
}

export function resolvePropertySelection(selection: PropertySelection | PropertySelectionByName): PropertySelection {
  if (!isPropertySelectionByName(selection)) return selection;
  const { asset, property } = selection;
  return {
    asset,
    property: getProperty(asset, property)
  };
}

export function selectionKey(selection: PropertySelection | PropertySelectionByName): string {
  const descriptorObj = toDescriptorObject(selection.property);
  return `${selection.asset.assetUuid}-${descriptorObj.name}`;
}
