import { render, staticRenderFns } from "./BuildingFloorplansTab.vue?vue&type=template&id=bd54c3ae&"
import script from "./BuildingFloorplansTab.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingFloorplansTab.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BuildingFloorplansTab.vue?vue&type=style&index=0&id=bd54c3ae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VCol,VDialog,VRow,VSlideXReverseTransition,VSlideXTransition})
