import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { pick } from "lodash";
import { GqlAsset } from "@/types";
import { deviceLock } from "@/gql/fragments/deviceLock";

export default function (asset: Partial<GqlAsset>): Promise<GqlAsset> {
  const fields = pick(asset, ["buildingUuid", "name", "serialNumber"]);
  const properties = pick(asset, [
    "assetCategoryUuid",
    "assetTypeUuid",
    "manufacturerUuid",
    "assetModelUuid",
    "installationDate"
  ]);

  return apolloClient
    .mutate({
      mutation: gql`
        ${deviceLock}
        mutation ($buildingUuid: ID!, $name: String!, $properties: CreateAssetProperties, $serialNumber: String) {
          createAsset(buildingUuid: $buildingUuid, name: $name, properties: $properties, serialNumber: $serialNumber) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            installationDate
            floor {
              position
              name
            }
            floorUuid
            floorX
            floorY
            building {
              buildingUuid
              name
              timeZone
            }
            ... on Device {
              properties
              settings
              miscFields
              thresholds {
                values
              }
              ...DeviceLockFields
            }
            childLinks {
              linkType
              childAsset {
                assetUuid
                knownAssetUuid
                name
              }
            }
          }
        }
      `,
      variables: {
        ...fields,
        properties
      }
    })
    .then(response => {
      return response.data.createAsset;
    });
}
