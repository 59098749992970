import Vue from "vue";
import { NormalizedScopedSlot, ScopedSlotChildren } from "vue/types/vnode";
import { useRouter } from "vue-router/composables";

export type SlotRendererFn = (slot: NormalizedScopedSlot | undefined, props: Record<any, any>) => string | undefined;

export function useSlotRenderer(): SlotRendererFn {
  const router = useRouter();
  const rendererComponent = Vue.extend({
    router,
    props: {
      vnodes: { type: Array, required: true }
    },
    render: function (h) {
      return h("div", this.vnodes as ScopedSlotChildren);
    }
  });

  const rendererFn: SlotRendererFn = (slot, props) => {
    if (slot === undefined) return undefined;

    const vnodes = slot(props);
    if (vnodes === undefined) return undefined;

    const renderer = new rendererComponent({
      propsData: { vnodes }
    });

    renderer.$mount();
    return renderer.$el.innerHTML;
  };

  return rendererFn;
}
