import { render, staticRenderFns } from "./AssetPropertyMenuGroup.vue?vue&type=template&id=2a487ed6&"
import script from "./AssetPropertyMenuGroup.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AssetPropertyMenuGroup.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VListItem,VListItemTitle,VSubheader})
