import { Ref, ref } from "vue";
import gql from "graphql-tag";
import { useLazyQuery } from "@vue/apollo-composable";
import { Comment } from "@/types";
import deleteCommentMutation from "@/gql/delete-comment-mutation";
import updateCommentMutation from "@/gql/update-comment-mutation";

export interface UseCommentResult {
  comment: Ref<Comment | undefined>;
  commentLoading: Ref<boolean>;
  loadComment: () => Promise<any> | undefined;
  deleteComment: (comment: Comment) => Promise<any>;
  updateComment: (comment: Comment, contents: string) => Promise<any>;
  commentError: Ref<any>;
}

export function useComment(commentUuid: string): UseCommentResult {
  const comment: Ref<Comment | undefined> = ref();

  const {
    load,
    refetch,
    onResult,
    loading: commentLoading,
    error: commentError
  } = useLazyQuery(
    gql`
      query Comment($commentUuid: ID!) {
        comment: comment(commentUuid: $commentUuid) {
          commentUuid
          comment
          stamp
          updated
          user {
            emailAddress
            contactInfo {
              emailAddress
              name
            }
          }
        }
      }
    `,
    {
      commentUuid: commentUuid
    }
  );
  const loadComment = (): Promise<any> | undefined => {
    return load() || refetch();
  };
  onResult(queryResult => {
    if (queryResult.data) {
      comment.value = queryResult.data.comment;
    }
  });

  function deleteComment(comment: Comment): Promise<any> {
    return deleteCommentMutation(comment.commentUuid);
  }

  function updateComment(comment: Comment, contents: string): Promise<any> {
    return updateCommentMutation(comment.commentUuid, contents);
  }

  return {
    comment,
    loadComment,
    commentLoading,
    deleteComment,
    updateComment,
    commentError
  };
}
